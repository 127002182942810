import React from 'react';
import { Link } from 'gatsby';

import DeadFishIcon from 'components/svg/DeadFishIcon';
import { HOMEPAGE_ROUTE } from 'utils/routes';

import styles from './NotFound.module.css';

export const NotFound: React.FC = () => (
  <div className={styles.page}>
    <div className={styles.header}>
      <DeadFishIcon className={styles.headerIcon} />
      <h1 className={styles.title}>You Went Deep</h1>
      <p className={styles.titleSubcopy}>Page not found.</p>
    </div>
    <div className={styles.body}>
      <Link to={HOMEPAGE_ROUTE} className={styles.btn}>
        Go Home
      </Link>
    </div>
  </div>
);
