import React from 'react';

import Layout from 'components/layout';
import { NOT_FOUND_SEO } from 'utils/seo';
import { NotFound } from 'components/NotFound';

const NotFoundPage: React.FC = () => (
  <Layout {...NOT_FOUND_SEO}>
    <NotFound />
  </Layout>
);

export default NotFoundPage;
